import styled from 'styled-components'
import Container from './Container'
import H1 from './H1'
import Image from './Image'
import ImageContainer from './ImageContainer'
import Text from './Text'

const ClickoutPage = styled('div')`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  font-family: Montserrat, sans-serif;
`

ClickoutPage.Container = Container
ClickoutPage.ImageContainer = ImageContainer
ClickoutPage.Image = Image
ClickoutPage.Text = Text
ClickoutPage.H1 = H1

export default ClickoutPage
