import styled from 'styled-components'

const ImageContainer = styled('div')`
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-bottom: 20px;
  position: relative;
`

export default ImageContainer
