import styled from 'styled-components'

const Container = styled('div')`
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

export default Container
