import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useCallback, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import APIClient from '../api-client'

import ClickoutPage from '../components/blocks/ClickoutPage'

const Clickout = (props) => {
  const [data, setData] = useState(undefined)

  const { click_id: clickId, url_id: urlId } = queryString.parse(props.location.search)

  const getProduct = useCallback(
    async (clickId) => {
      const items = await APIClient.getItemBySku(clickId)

      if (items.length > 0) {
        const item = items[0]
        setData(item)
        window.dataLayer = window.dataLayer || []

        if (window.dataLayer && window.dataLayer.push) {
          window.dataLayer.push({
            amberCatId: item.amberMasterCatId,
            merchantName: item.merchantName,
            simpleSku: item.simpleSku,
            prodTitle: item.prodTitle,
            prodBrand: item.prodBrand,
            prodColor: item.prodColor,
            prodPrice: item.prodPrice,
            prodPriceOld: item.prodPriceOld,
            prodRanking: item.prodRanking,
            pageType: 'clickout Page',
            event: 'productClick'
          })
        }

        const timer = setTimeout(() => {
          window.location.href = item.deeplink || item.deepLink
          return null
        }, 1000)

        return () => clearTimeout(timer)
      } else {
        await navigate('/' + urlId)
      }
    },
    [urlId]
  )

  const Image = useMemo(() => {
    if (data) {
      return (
        <ClickoutPage.ImageContainer>
          <ClickoutPage.Image
            src={data.prodImgUrl1}
            alt={data.prodTitle}
          />
        </ClickoutPage.ImageContainer>
      )
    }
    return <ClickoutPage.ImageContainer />
  }, [data])

  if (!data && clickId) {
    getProduct(clickId)
  }
  return (
    <>
      <Helmet
        title='amber living'
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow'
          },
          {
            name: 'referrer',
            content: 'origin-when-cross-origin'
          }
        ]}
      />
      <ClickoutPage>
        <ClickoutPage.Container>
          {Image}
          <ClickoutPage.H1>amber-living</ClickoutPage.H1>
          <ClickoutPage.Text>
            Vielen Dank. Sie werden nun weitergeleitet!
          </ClickoutPage.Text>
        </ClickoutPage.Container>
      </ClickoutPage>
    </>
  )
}

export default Clickout
